<template>
  <div class="overflow-x-auto w-full">
    <Field
      v-if="data.eventList.length > 0"
      v-model="data.searchInfo"
      @input="debounceSearchData"
      type="text"
      name="search"
      placeholder="Input event name to search"
      class="
        mt-4
        block
        w-full
        appearance-none
        dark:text-white
        bg-off-white-1
        dark:bg-dark-bgPrimary
        text-xs
        md:text-base
        focus:ring-magenta focus:border-magenta
        rounded
        py-2
        md:py-3
        px-2
        md:px-4
        mb-3
      "
    />
    <table class="text-left w-full">
      <thead>
        <tr>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer" @click="sort('name')">
            <div class="flex items-center relative p-3 md:p-3.5 lg:py-4 lg:px-7">
              <SortArrowDownIcon v-if="data.currentSort === 'name' && data.currentSortDir === 'desc'" class="absolute w-4 h-4 left-0" />
              <SortArrowUpIcon v-if="data.currentSort === 'name' && data.currentSortDir === 'asc'" class="absolute w-4 h-4 left-0" />
              <span :class="{ 'pl-2 lg:pl-0': data.currentSort === 'name' }"> Event Name </span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer" @click="sort('dateTime')">
            <div class="flex items-center relative p-3 md:p-3.5 lg:py-4 lg:px-7">
              <SortArrowDownIcon v-if="data.currentSort === 'dateTime' && data.currentSortDir === 'desc'" class="absolute w-4 h-4 left-0" />
              <SortArrowUpIcon v-if="data.currentSort === 'dateTime' && data.currentSortDir === 'asc'" class="absolute w-4 h-4 left-0" />
              <span :class="{ 'pl-2 lg:pl-0': data.currentSort === 'dateTime' }"> Event Date </span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold w-20">
            <div class="flex items-center relative p-3 md:p-3.5 lg:py-4 lg:px-7">
              <span>Edit</span>
            </div>
          </th>
          <template v-if="isAdmin">
            <th class="text-xs md:text-sm text-grey-mid font-bold w-20">
              <div class="flex items-center relative p-3 md:p-3.5 lg:py-4 lg:px-7">
                <span>Delete</span>
              </div>
            </th>
            <th class="text-xs md:text-sm text-grey-mid font-bold w-0 md:w-20">
              <div class="md:flex items-center relative p-3 md:p-3.5 lg:py-4 lg:px-7">
                <span>Conclude</span>
              </div>
            </th>
            <th class="text-xs md:text-sm text-grey-mid font-bold w-0 md:w-20">
              <div class="md:flex items-center relative p-3 md:p-3.5 lg:py-4 lg:px-7">
                <span>Pre/Posts Views</span>
              </div>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-if="!data.isLoading">
          <tr
            v-for="(e, index) in data.eventsListDataTable"
            :key="index"
            :class="{
              'bg-off-white-1 dark:bg-dark-bgPrimary': index % 2 === 0,
            }"
          >
            <td
              class="text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium p-3 md:p-3.5 lg:py-4 lg:px-7 cursor-pointer"
              @click="
                $router.push({
                  name: 'EventDetail',
                  params: { eventId: e.id },
                })
              "
            >
              {{ e.name }}
            </td>
            <td class="text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium p-3 md:p-3.5 lg:py-4 lg:px-7">
              {{ $filters.formatDate(e.dateTime) }}
            </td>
            <td class="text-xs md:text-sm lg:text-base text-magenta font-medium p-3 md:p-3.5 lg:py-4 lg:px-7">
              <EditIcon
                class="h-5 w-5 md:h-7 md:w-7 cursor-pointer"
                @click="
                  $router.push({
                    name: 'EventDetail',
                    params: { eventId: e.id },
                  })
                "
              />
            </td>
            <template v-if="isAdmin">
              <td class="text-xs md:text-sm lg:text-base text-magenta font-medium p-3 md:p-3.5 lg:py-4 lg:px-7">
                <DeleteIcon class="h-5 w-5 md:h-7 md:w-7 cursor-pointer" @click="onDelete(e.id)" />
              </td>
              <td class="text-xs md:text-sm lg:text-base text-magenta font-medium lg:px-7 cursor-pointer text-center" @click="doChangeStatusEvent(e)">
                <component :is="e.isConclude ? 'LockIcon' : e.isPreEvent ? 'UnLockIcon' : 'Launching'" class="h-5 w-5 md:h-7 md:w-7 m-auto" />
                <p class="text-xs" v-text="e.isConclude ? 'Concluded' : e.isPreEvent ? 'Pre-Event' : 'Launching'" />
              </td>
              <td class="text-xs md:text-sm lg:text-base text-magenta font-medium p-3 md:p-3.5 lg:py-4 lg:px-7">
                <EyeIcon class="h-5 w-5 md:h-7 md:w-7 cursor-pointer" @click="doUpdatePrePostEvent(e)" />
              </td>
            </template>
          </tr>
        </template>

        <template v-if="data.isLoading">
          <tr
            v-for="n in 10"
            :key="n"
            :class="{
              'bg-grey-light dark:bg-dark-bgPrimary': n % 2 !== 0,
            }"
            class="h-14"
          >
            <td></td>
            <td></td>
            <td></td>
            <template v-if="isAdmin">
              <td></td>
              <td></td>
              <td></td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-if="data.eventsListDataTable.length" class="flex border-t-2 border-grey-light pt-4 lg:pt-7">
      <button
        class="
          w-16
          md:w-20
          h-8
          md:h-10
          text-xs
          md:text-base
          uppercase
          font-bold
          text-grey-mid
          dark:text-dark-textPrimary
          bg-white
          dark:bg-dark-bgSecondary
          border-2 border-grey-light
          dark:border-dark-bgPrimary
          rounded-full
          mr-2
          focus:outline-none
        "
        @click.prevent="paginate(1)"
      >
        First
      </button>

      <div v-for="page in totalPages" :key="`pagination-${page}`">
        <span
          v-if="isDisplayPageNumber(page)"
          :class="page === data.currentPage ? 'border-blue-light' : 'border-grey-light dark:border-dark-bgPrimary'"
          class="flex justify-center items-center w-8 md:w-10 h-8 md:h-10 text-grey-mid dark:text-dark-textPrimary rounded-full border-2 shadow-sm cursor-pointer mr-2"
          @click="paginate(page)"
          >{{ page }}</span
        >
      </div>

      <button
        class="
          w-16
          md:w-20
          h-8
          md:h-10
          text-xs
          md:text-base
          uppercase
          font-bold
          text-grey-mid
          dark:text-dark-textPrimary
          bg-white
          dark:bg-dark-bgSecondary
          border-2 border-grey-light
          dark:border-dark-bgPrimary
          rounded-full
          mr-2
          focus:outline-none
        "
        @click.prevent="paginate(totalPages)"
      >
        Last
      </button>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onBeforeMount, h, watch } from "vue";
import { useStore } from "vuex";

import { dynamicPaginate, filterDataCustom } from "@Helpers/datatable";
import { sleep } from "@Helpers/sleep";
import { useTableData } from "@Libs/hooks/useDataTable";
import { deleteEvent, doUpdateStatusEvent } from "@API/event/event-repository";

import SortArrowUpIcon from "@Assets/images/SortArrowUpIcon";
import SortArrowDownIcon from "@Assets/images/SortArrowDownIcon";
import EditIcon from "@Assets/images/EditIcon";
import DeleteIcon from "@Assets/images/DeleteIcon";
import EyeIcon from "@Assets/images/EyeIcon";
import LockIcon from "@Assets/images/LockIcon";
import UnLockIcon from "@Assets/images/UnLockIcon";
import Launching from "@Assets/images/Launching";
import ConcludeEvent from "./ConcludeEvent";
import PrepareTab from "../event-detail/PrepareTab.vue";
import { get, debounce } from "lodash";
import { empty } from "@/utils";
import { Field } from "vee-validate";

export default {
  name: "EventsListTable",
  components: {
    SortArrowUpIcon,
    SortArrowDownIcon,
    EditIcon,
    DeleteIcon,
    EyeIcon,
    LockIcon,
    UnLockIcon,
    Launching,
    ConcludeEvent,
    PrepareTab,
    Field,
  },
  setup() {
    const store = useStore();

    const data = reactive({
      curerntFilterText: "",
      currentSort: "name",
      currentSortDir: "asc",
      currentPage: 1,
      perPage: 5,
      isLoading: false,
      eventList: [],
      searchInfo: "",
      eventsListDataTable: [],
    });
    const sortStr = computed({
      get: () => {
        let s = "";
        if (data.currentSortDir === "desc") s += "-";
        return s + data.currentSort;
      },
      set: s => {
        //if s == current sort, reverse
        if (s === data.currentSort) {
          data.currentSortDir = data.currentSortDir === "asc" ? "desc" : "asc";
        } else data.currentSortDir = "asc";
        data.currentSort = s;
      },
    });
    const totalPages = computed(() => Math.ceil(data.eventList.length / data.perPage));

    const isAdmin = computed(() => store.getters.isAdmin);

    onBeforeMount(getEvents);
    watch(
      () => data.eventList,
      value => {
        data.searchInfo = "";
        data.eventsListDataTable = dynamicPaginate(value, data.currentPage, data.perPage);
      },
    );

    watch(
      () => data.currentPage,
      value => {
        data.eventsListDataTable = dynamicPaginate(data.eventList, value, data.perPage);
      },
    );

    const onSearch = () => {
      let dataRet = dynamicPaginate(data.eventList, data.currentPage, data.perPage);
      if (!empty(data.searchInfo)) {
        dataRet = filterDataCustom(data.searchInfo, data.eventList, ["name"]);
      }
      data.eventsListDataTable = dataRet;
    };
    const debounceSearchData = debounce(onSearch, 300);

    async function getEvents(isForce = true) {
      try {
        // Show loading
        data.searchInfo = "";
        if (isForce) {
          toggleLoading();
        } else {
          store.dispatch("updateLoadingText", "Getting event's data");
          store.dispatch("toggleLoading");
        }
        const { fetchEventsListDataTable } = useTableData();

        data.eventList = await fetchEventsListDataTable(data.curerntFilterText, sortStr.value);
      } catch (error) {
        console.log({ error });
      } finally {
        // Hide Loading
        if (isForce) {
          toggleLoading(false);
        } else {
          store.dispatch("closeLoading");
        }
      }
    }

    async function sort(s) {
      sortStr.value = s;

      getEvents(false);
    }

    async function filter(f) {
      data.curerntFilterText = f;

      getEvents(false);
    }

    function paginate(p) {
      if (p === data.currentPage) return;

      // Set currentPage
      data.currentPage = p;
    }

    function toggleLoading(isLoading = true) {
      data.isLoading = isLoading;
    }

    function isDisplayPageNumber(p) {
      if (totalPages.value < 3) return true;
      if (p >= data.currentPage - 1 && p <= data.currentPage + 1) return true;
      return false;
    }

    return {
      data,
      debounceSearchData,
      totalPages,
      getEvents,
      sort,
      filter,
      paginate,
      isDisplayPageNumber,
      isAdmin,
    };
  },
  beforeUnmount() {
    this.emitter.all.delete("submit-confirm-dialog-event-list");
    this.emitter.all.delete("header-input-change");
  },
  mounted() {
    // Listen header input change text
    this.emitter.on("header-input-change", searchText => {
      if (this.$route.name !== "EventList") return;
      this.$store.commit("SET_EVENT_SEARCH_TEXT", searchText);
      this.filter(searchText);
    });

    // Listen submit confirm dialog
    this.emitter.on("submit-confirm-dialog-event-list", async data => {
      this.$store.dispatch("toggleLoading");
      try {
        const { type, payload } = this.$store.state.auth.confirmDialog.confirmAction;
        let result;
        switch (type) {
          case "DELETE_EVENT":
            this.$store.dispatch("updateLoadingText", "Removing event");
            result = await deleteEvent(payload);
            break;
          case "CONCLUDE_EVENT":
            this.$store.dispatch("updateLoadingText", "Updating event's status");
            result = await doUpdateStatusEvent(payload, {
              type: data.type.value,
              // message: data.type.label,
            });
            break;
          default:
            return;
          // case "TICKER_BAR_EVENT":
          //   result = await putEvent(payload);
          //   if (result.success) {
          //     this.$store.dispatch("updateCurrentEvent", result.data);
          //   }
          //   break;
        }
        // Show Alert
        if (!empty(result)) {
          this.$store.dispatch("toggleAlert", {
            isAlert: true,
            status: result.success,
            title: result.success ? "Success!" : "Failed!",
            message: result.message,
          });
        }
        this.$store.dispatch("resetConfirmDialog");
      } catch (error) {
        console.error({ error });
      } finally {
        this.$store.dispatch("closeLoading");
        this.getEvents(false);
        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    });
  },
  methods: {
    get,
    doChangeStatusEvent(event) {
      // Show confirm dialog
      // const message = event.isConclude
      //   ? "Are you sure you want to start selected event?"
      //   : "Are you sure you want to conclude selected event?";
      // const component = ConcludeEvent;
      const confirmButtonText = "Submit";
      const title = "Update event's status";
      this.$store.dispatch("toggleConfirmDialog", {
        eventListen: "submit-confirm-dialog-event-list",
        isConfirmDialog: true,
        title,
        component: h(ConcludeEvent, {
          initValue: event.isConclude ? "CLOSED" : event.isPreEvent ? "PRE" : "OPEN",
          message: event.isConclude ? event.endedMessage : event.isPreEvent ? event.preEventMessage : "",
        }),
        confirmButtonText,
        confirmAction: {
          type: "CONCLUDE_EVENT",
          payload: event.id,
        },
      });
    },
    doUpdatePrePostEvent(event) {
      const confirmButtonText = "Submit";
      const title = "Update Pre/Post Views";
      this.$store.dispatch("toggleConfirmDialog", {
        eventListen: "submit-confirm-dialog-event-list",
        customCSS: {
          popupContainer: ["md:max-w-34rem"],
          contentContainer: ["text-left"],
        },
        isConfirmDialog: true,
        title,
        component: h(PrepareTab, {
          eventDetail: event,
          isPopup: true,
          class: ["mt-4"],
        }),
        confirmButtonText,
        confirmAction: {
          type: "PRE_POST_VIEWS",
        },
      });
    },
    // doUpdateTickerBar(event) {
    //   const isShowTickerBar =
    //     get(event, "settings.bottomBar", "SHOW") === "SHOW";

    //   // Show confirm dialog
    //   const message = isShowTickerBar
    //     ? "Are you sure you want to HIDE ticker bar of selected event?"
    //     : "Are you sure you want to SHOW ticker bar of selected event?";
    //   const confirmButtonText = isShowTickerBar ? "Hide" : "Show";
    //   const title = isShowTickerBar ? "Hide Ticker" : "Show Ticker";

    //   this.$store.dispatch("toggleConfirmDialog", {
    //     isConfirmDialog: true,
    //     title,
    //     message,
    //     confirmButtonText,
    //     confirmAction: {
    //       type: "TICKER_BAR_EVENT",
    //       payload: {
    //         ...event,
    //         settings: {
    //           ...event.settings,
    //           bottomBar: isShowTickerBar ? "HIDE" : "SHOW",
    //         },
    //       },
    //     },
    //   });
    // },
    onDelete(eventId) {
      // Show confirm dialog
      this.$store.dispatch("toggleConfirmDialog", {
        eventListen: "submit-confirm-dialog-event-list",
        isConfirmDialog: true,
        title: "Delete event",
        message: "Are you sure you want to delete selected event? All of your data will be permanently removed. This action cannot be undone.",
        confirmButtonText: "Delete",
        confirmAction: {
          type: "DELETE_EVENT",
          payload: eventId,
        },
      });
    },
  },
};
</script>
