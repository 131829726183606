<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g fill="currentColor">
        <path
          d="m350.3,255.1c7.9-87.6-22.7-176.9-86.9-241.1-3.9-3.9-10.8-3.9-14.7,0-64.2,64.2-94.8,153.4-86.9,241.1-22.2,23.9-34.4,54.7-34.4,87.5 0,33.1 12.6,64.6 35.5,88.6 5.6,5.9 19.9,3.2 17.6-9.8-1.2-6.8-2.7-13.4-2.7-19.9 0-16.2 4.9-31.6 14-44.6 1.5,0.8 3.2,1.3 5,1.3h118.5c1.8,0 3.5-0.5 5-1.3 9.1,13 14,28.4 14,44.6 0,6.5-1.7,13-2.7,19.9-2.1,14.4 12.9,14.9 17.6,9.8 22.8-24.1 35.5-55.5 35.5-88.6-0.1-32.8-12.2-63.7-34.4-87.5zm-94.3-218.7c18.6,20 33.8,42.5 45.6,66.4h-91.1c11.7-24 26.9-46.4 45.5-66.4zm-98,351c-6.4-13.9-9.7-29.1-9.7-44.8 0-21.2 6.1-41.5 17.5-58.9 0.1,0.8 11,42.5 15.2,53.2-12.5,14.4-20.4,31.8-23,50.5zm150.4-50.1h-104.8c-29.4-69-29.1-146.1-2.4-213.8h109.6c26.7,67.7 27,144.8-2.4,213.8zm22.7-.4c5.8-14.9 15.1-52.6 15.2-53.2 11.4,17.3 17.5,37.6 17.5,58.9 0,15.7-3.4,30.9-9.7,44.8-2.7-18.7-10.6-36.1-23-50.5z"
        />
        <path
          d="m213.9,375.6c-5.8,0-10.4,4.7-10.4,10.4v52.3c0,5.8 4.7,10.4 10.4,10.4s10.4-4.7 10.4-10.4v-52.3c0-5.8-4.7-10.4-10.4-10.4z"
        />
        <path
          d="m256,375.6c-2.9,0-5.5,1.2-7.4,3.1 0,0 0,0-0.1,0.1-1.2,1.2-2.1,2.8-2.6,4.6-0.2,0.9-0.4,1.8-0.4,2.8v104.5c0,0.8 0.1,1.6 0.3,2.4 1.1,4.6 5.2,8 10.1,8s9-3.4 10.1-8c0.2-0.8 0.3-1.6 0.3-2.4v-104.7c0-1-0.1-1.9-0.4-2.8-1.1-4.4-5.1-7.6-9.9-7.6z"
        />
        <path
          d="m298.1,375.6c-5.8,0-10.4,4.7-10.4,10.4v52.3c0,5.8 4.7,10.4 10.4,10.4s10.4-4.7 10.4-10.4v-52.3c0.1-5.8-4.6-10.4-10.4-10.4z"
        />
      </g>
    </g>
  </svg>
</template>
